import React from 'react';
import './Services.css';
import { Link } from 'react-router-dom';

const Services = () => {
    return (
        <main className="services">
            <section className="services-grid">
                <div className="service-box">
                    <h3>Desenvolvimento Web</h3>
                    <p>Criação de sites e aplicações web sob medida.</p>
                </div>
                <div className="service-box">
                    <h3>Consultoria</h3>
                    <p>Consultoria especializada em tecnologia e processos.</p>
                </div>
                <div className="service-box">
                    <h3>Suporte Técnico</h3>
                    <p>Suporte contínuo para manter suas soluções funcionando.</p>
                </div>
                <div className="service-box">
                    <h3>Integrações</h3>
                    <p>Integração de sistemas e APIs para soluções completas.</p>
                </div>
                <div className="service-box contact-box">
                    <h3>Entre em Contato</h3>
                    <p>Quer saber mais sobre nossos serviços?</p>
                    <Link to="/contact" className='contact-link'>Contate-nos</Link>
                </div>
            </section>
        </main>
    );
};

export default Services;
