import React from 'react';
import './Contact.css';

const Contact = () => {
    return (
        <main className="contact">
            <section className="contact-info">
                <h2>Entre em Contato</h2>
                <p>Estamos aqui para ajudar você com suas necessidades de software.</p>
                <div className="contact-details">
                    <p>
                        <strong>Email:</strong> <a href="mailto:oliveiradevs.softhouse@gmail.com">oliveiradevs.softhouse@gmail.com</a>
                    </p>
                    <p>
                        <strong>Instagram:</strong> <a href="https://instagram.com/oliveiraDevs" target="_blank" rel="noopener noreferrer">@oliveiraDevs</a>
                    </p>
                    <p>
                        <strong>CEO:</strong> <a href="https://instagram.com/m_oliveira404" target="_blank" rel="noopener noreferrer">@m_oliveira404</a>
                    </p>
                </div>
            </section>
        </main>
    );
};

export default Contact;