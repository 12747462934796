import React from 'react';
import './Home.css'; // Importando o CSS específico da Home

const Home = () => {
    return (
        <main className="home">
            <div className="overlay">
                <section className="hero">
                    <h2>Bem-vindo à OliveiraDevs</h2>
                    <p>
                        Transformamos suas ideias em soluções de software sob demanda com eficiência e qualidade.
                    </p>
                </section>
            </div>
        </main>
    );
};

export default Home;