import React from 'react';
import './About.css';

const About = () => {
    return (
        <main className="about">
            <div className="overlay">
                <section className="hero">
                    <h2>Sobre Nós</h2>
                    <p>
                        A OliveiraDevs é uma fábrica de software dedicada a desenvolver soluções sob medida para seus
                        clientes. Com uma equipe altamente qualificada e anos de experiência, entregamos resultados
                        que transformam negócios.
                    </p>
                </section>
            </div>
        </main>
    );
};

export default About;